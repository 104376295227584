// Create script and link tags to import initial widget chunks
(function loadChunks(d) {
  var domain = process.env.WIDGET_URL || '';
  var filePrefix = 'drvtrd-';
  var chunks = [
    { name: 'runtime' },
    { name: 'vendors', hasCss: false },
    { name: 'main', hasCss: false },
  ];

  chunks.forEach(function (chunk) {
    // JS
    var r = `${domain}${filePrefix}${chunk.name}.js`;
    if (!d.querySelector(`[src="${r}"]`)) {
      var script = d.createElement('script');
      script.src = r;
      script.defer = !0;
      script.async = !0;
      d.body.append(script);
    }

    if (chunk.hasCss) {
      // CSS
      var rc = `${domain}${filePrefix}${chunk.name}.css`;
      if (!d.querySelector(`[href="${rc}"]`)) {
        var link = d.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = rc;
        d.head.append(link);
      }
    }
  });
})(document);
